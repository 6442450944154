import React, { useRef } from "react"
import { Layout } from "@components/layout"
import { useStaticQuery, graphql } from "gatsby"

import {
  RoadMapHero,
  Objective,
  Benefits,
  Why,
  RoadmapPaths,
  RoadMapOffer,
  Faq,
  PaymentModal,
} from "@components/standard"
const Roadmaps = ({ pageContext }) => {
  const { page, productsWithDetails } = pageContext
  const paymentModalRef = useRef()

  // faq questions query
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        page(id: "cG9zdDoxNzgw") {
          ACF_FaqRoadmaps {
            questions {
              a
              q
            }
          }
        }
      }
    }
  `)
  const {questions} = WP.page.ACF_FaqRoadmaps
  return (
    <Layout {...page}>
      <RoadMapHero />
      <Objective />
      <Benefits />
      <Why />
      <RoadmapPaths />
      <RoadMapOffer products={productsWithDetails} ref={paymentModalRef} />
      <Faq questions={questions}/>
      <PaymentModal ref={paymentModalRef} />
    </Layout>
  )
}

export default Roadmaps

